import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../resources/css/animations.css';
import ClientCountup from '../misc/ClientCountup';
import { MainContainer } from '../misc/containers';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import CSSTransition from 'react-transition-group/CSSTransition';
import { device } from '../../resources/devices';

const Text = styled.div`
  font-size: 1.5rem;
  font-weight: bolder;
  padding: 1rem;
`;
const StyledMain = styled(MainContainer)`
  @media screen and ${device.desktop} {
    width: 30vw;
  }
  width: 95vw;
  justify-content: center;
`;

const HomePage = () => {
  const { t } = useTranslation('home_page');
  return (
    <CSSTransition appear={true} in={true} classNames="fadein" timeout={1000}>
      <StyledMain>
        <Row className="justify-content-center">
          <Text>{t('main_text')}</Text>
        </Row>
        <Row className="justify-content-center">
          <ClientCountup />
        </Row>
      </StyledMain>
    </CSSTransition>
  );
};

export default HomePage;
