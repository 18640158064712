import React from 'react';
import styled from 'styled-components';
import { useCountUp } from 'react-countup';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  font-family: 'PT Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: bolder;
  color: white;
  align-items: center;
  background: linear-gradient(45deg, #f85047 30%, #ff8e53 90%);
  border-radius: 1rem;
  padding: 0 1rem;
  width: fit-content;
`;

const Counter = styled.span`
  display: flex;
  font-size: 3.5rem;
  margin-right: 0.5rem;
`;

const Clients = styled.span`
  display: flex;
  text-align: center;
`;

const ClientCountup = () => {
  const { t } = useTranslation('common');
  const { countUp } = useCountUp({
    end: 23585,
    duration: 10,
  });
  return (
    <Wrapper>
      <Counter>{countUp}</Counter>
      <Clients>{t('clients')}</Clients>
    </Wrapper>
  );
};

export default ClientCountup;
