import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HomePage from '../components/page/Home';
import pages from '../resources/pages.json';

const IndexPage = () => (
  <Layout>
    <SEO title={pages.home.title} description={pages.home.description} />
    <HomePage />
  </Layout>
);

export default IndexPage;
